import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  image?: string
  label?: string
}

export const Service = memo(function Service({ icon, image, label }: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {image ? (
        <Background>
          <Image style={{ backgroundImage: `url(${image})` }} />
        </Background>
      ) : null}
      <Wrapper>
        {icon ? <SVG src={icon} width="40" height="30" alt={label} /> : null}
        {label ? <Label>{label}</Label> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(motion.div)`
  width: 25%;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  padding-bottom: 25%;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding-bottom: 43%;
    &:nth-of-type(3) {
      order: 3;
    }
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;
`

const SVG = styled.img`
  width: auto;
  height: 1.875rem;
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 1.25rem;
  text-transform: uppercase;
`
