import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  label?: string
  services?: ServiceProps[]
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  cta,
  label,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      <Services row wrap stretch>
        {services.slice(0, 1).map((item, index) => (
          <Service key={index} {...item} />
        ))}
        <Wrapper dial={5}>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </Wrapper>
        {services.slice(1, services.length).map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
      {cta ? <CTA variant="S" {...cta} /> : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 10rem auto;
  padding: 3.75rem 10.972vw 4.0625rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 17.917vw;
    left: 17.917vw;
  }

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
    margin-bottom: 3.75rem;
    padding: 1.875rem 0 3rem;
    &:before {
      right: 1.9375rem;
      left: 1.9375rem;
    }
  }
`

const Wrapper = styled(FlexBox)`
  width: 50%;
  padding: 0 3.75rem;

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 1.875rem;
    padding: 0 1.9375rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  &:before {
    content: '';
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin: 0 auto 0.625rem;
    transform: rotate(45deg);
  }
`

const Title = styled.h2`
  max-width: 15.375rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.25rem;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Services = styled(FlexBox)``

const CTA = styled(Button)`
  margin: 3.4375rem auto 0;
`
