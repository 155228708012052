import 'keen-slider/keen-slider.min.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  images?: ImageProps[]
  label?: string
  title?: string
}

export const VisualBanner = memo(function VisualBanner({
  cta,
  images,
  label,
  title,
}: Props) {
  const [details, setDetails] = useState<any>(null)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    duration: 2000,
    loop: true,
    move(s) {
      setDetails(s.details())
    },
  })

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.positions[idx]
    const x = details.widthOrHeight * -position.distance
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  return (
    <Container>
      {images ? (
        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Inner style={positionStyle(index)}>
                <LazyLoadImage effect="blur" {...item} />
              </Inner>
            </Slide>
          ))}
        </Slider>
      ) : null}
      {images && images.length > 1 ? (
        <Arrows row>
          <Arrow onClick={(e) => e.stopPropagation() || slider.prev()} />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || slider.next()}
          />
        </Arrows>
      ) : null}
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </Wrapper>
      {cta ? <CTA {...cta} /> : null}
    </Container>
  )
})

const Container = styled.section`
  height: 48.611vw;
  max-height: 700px;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    height: auto;
    max-height: none;
    padding-top: 7.5rem;
    padding-bottom: 10rem;
    text-align: center;
  }
`

const Slider = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;

  @media (max-width: 1023px) {
    right: 50%;
    transform: translateX(50%);
  }
`

const Wrapper = styled.div`
  width: 100%;
  padding: 0 10.972vw;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;

  @media (max-width: 1199px) {
    padding-right: 3.375rem;
    padding-left: 3.375rem;
  }

  @media (max-width: 1023px) {
    position: relative;
    top: auto;
    transform: none;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  &:before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin: 0 auto 0.625rem;
    transform: rotate(45deg);
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 5rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @media (max-width: 1023px) {
    margin: 2.5rem auto 0;
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
  }
`
