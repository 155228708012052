import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  cta,
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container row tag="section" wrap stretch>
      <LeftSide>
        <Background>
          {image ? <LazyLoadImage effect="blur" {...image} /> : null}
        </Background>
      </LeftSide>
      <RightSide dial={4}>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta ? (
          <FadeInUp>
            <Button variant="S" {...cta} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 12.5rem auto;
  padding: 0 10.972vw;

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto 5.625rem;
  }
`

const LeftSide = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    margin-top: 3rem;
  }
`

const Background = styled.div`
  margin-right: 7.708vw;
  padding-bottom: 100%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 2.25rem;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 2.25rem;
  }

  @media (max-width: 1023px) {
    margin-right: 1.25rem;
    padding-bottom: 70%;
    &:before {
      top: 1.25rem;
    }
    img {
      left: 1.25rem;
    }
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin-bottom: 0.75rem;
  padding-left: 1rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 0.25rem;
    left: 0;
    transform: rotate(45deg);
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.25rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 2.625rem;
`
