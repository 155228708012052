import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  label?: string
  languageCode: string
  image?: { [key: string]: any }
  subtitle?: string
  title?: string
}

export const Sticker = memo(function Sticker({
  cta,
  description,
  label,
  languageCode,
  image,
  subtitle,
  title,
}: Props) {
  const [modalStickerStatus, setModalStickerStatus] = useState(false)

  useEffect(() => {
    disableScroll.off()
  }, [])

  return (
    <Container>
      {label ? (
        <Label
          onClick={() => {
            if (!modalStickerStatus) {
              setModalStickerStatus(true)
              disableScroll.on()
            } else {
              disableScroll.off()
              setModalStickerStatus(false)
            }
          }}
        >
          <LabelTitle>{label}</LabelTitle>
          <LabelSubtitle>
            {useVocabularyData('click-here', languageCode)}
          </LabelSubtitle>
        </Label>
      ) : null}
      <Modal
        className={modalStickerStatus ? 'open' : ''}
        dial={5}
        onClick={() => {
          disableScroll.off()
          setModalStickerStatus(false)
        }}
      >
        <Wrapper row stretch onClick={(e) => e.stopPropagation()}>
          {image ? (
            <ImageWrap>
              <LazyLoadImage effect="blur" {...image} />
            </ImageWrap>
          ) : null}
          <TextWrap className={!image ? 'full-width' : undefined}>
            <ModalClose
              onClick={() => {
                disableScroll.off()
                setModalStickerStatus(false)
              }}
            />
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta ? <Button variant="S" {...cta} /> : null}
          </TextWrap>
        </Wrapper>
      </Modal>
    </Container>
  )
})

const Container = styled.aside`
  padding: 0 1.5rem;
`

const Label = styled.div`
  border: 1.5px solid ${rgba(theme.colors.variants.neutralLight4, 0.2)};
  border-right: 0;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.0375rem;
  line-height: 1.125rem;
  padding: 2.75rem 1.5rem 2.75rem 2.25rem;
  position: absolute;
  top: 20vh;
  right: 0;
  z-index: 300;
  transition: 0.3s ease-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    border-color: transparent;
    padding-right: 2.25rem;
  }

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    border: 0;
    padding: 1.25rem 1.9375rem;
    top: 70px;
    left: 0;
    text-align: center;
    &:hover {
      padding-right: 1.9375rem;
    }
  }
`

const LabelTitle = styled.div`
  max-width: 6.25rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    max-width: none;
  }
`

const LabelSubtitle = styled.div`
  font-style: italic;
  opacity: 0.3;
  margin-top: 0.4375rem;

  @media (max-width: 1199px) {
    margin-top: 0.3125rem;
  }
`

const Modal = styled(FlexBox)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

const ModalClose = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 20px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    position: absolute;
    top: 1.125rem;
    left: 0.625rem;
    transition: 0.3s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: default;
  margin: auto;
`

const ImageWrap = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05);
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const TextWrap = styled.div`
  width: 50%;
  padding: 3.75rem 3.75rem 4.875rem;
  position: relative;
  &.full-width {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.9375rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.25rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin-top: 0.375rem;
  padding-left: 1rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 0.25rem;
    left: 0;
    transform: rotate(45deg);
  }

  @media (max-width: 1023px) {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 2.625rem;
`
